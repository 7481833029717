export const uses = [
    {
        icon: './images/user.png',
        header: "Sign Up",
        content: "Sign up to access Lorem ipsum dolor sit amet consectetur. Convallis cras placerat dignissim aliquam massa.",
    },
    {
        icon: './images/book.png',
        header: "Book an Appointment",
        content: "Search Lorem ipsum dolor sit amet consectetur. Convallis cras placerat dignissim aliquam massaend.",
    },
    {
        icon: './images/virtual.png',
        header: "Have a Virtual Consultation",
        content: "Search Lorem ipsum dolor sit amet consectetur. Convallis cras placerat dignissim aliquam massaend.",
    },
    {
        icon: './images/visit.png',
        header: "Visit the Community",
        content: "Search Lorem ipsum dolor sit amet consectetur. Convallis cras placerat dignissim aliquam massaend.",
    },
]